@use '/src/styles/constants/colors';
@use '/src/styles/constants/levels';
@use '/src/styles/constants/sizes';

.gnb {
  position: fixed;
  top: 0;
  left: 50%;
  z-index: levels.$GNB;
  // z-index: 0;
  width: 100%;
  max-width: sizes.$MAX_WIDTH;
  background-color: colors.$WHITE;
  transform: translateX(-50%);
}

.logoWrapper {
  // @apply bg-white text-white px-4 py-2;
  @apply bg-white px-4 py-2;
  
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  border-bottom: 1px solid colors.$GRAYD;

  a {
    font-size: 18px;
    font-weight: 700;
    color: colors.$PRIMARY;
  }
}

.navBar {
  border-bottom: 1px solid colors.$GRAYD;

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  li {
    position: relative;
    text-align: center;
  }

  a {
    display: block;
    padding: 12px;
    font-weight: 900;
    color: colors.$GRAY1;
    transition: color 0.2s ease-in-out;
    font-size: calc(15px + 0.6vw); /* 가로 너비에 따라 조정될 크기 */

    &.isActive {
      color: colors.$PRIMARY;
    }
  }

  .indicator {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
    background: colors.$PRIMARY;
    transition: transform 0.2s ease-in-out;
  }
  .tap {
    font-size: 20px;
    font-weight: 700;
    color: colors.$PRIMARY;
  }
}

