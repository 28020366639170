@use '/src/styles/constants/colors';

.location {
  margin-top: 20px;
  font-size: 18px;
  font-weight: 700;
  color: colors.$GRAY1;
  text-align: center;
}

.map {
  width: 100%;
  height: 200px;
  margin-top: 16px;
}

.address {
  padding: 20px 0;
  line-height: 1.5;
  color: colors.$GRAY1;
  text-align: center;

  .addressItem {
    h4 {
      margin-bottom: 4px;
      font-weight: 700;
    }

    p {
      white-space: pre-wrap;
    }

    + .addressItem {
      margin-top: 14px;
    }
  }
}

.mapIcons {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  img {
    width: 50px;
    height: 50px;
    margin: 0 18px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 10%);
  }
}
