@use '/src/styles/constants/colors';

.buttonWrapper {
  display: flex;
  gap: 16px;
  // padding: 0 20px 40px;
}

.accountItem {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & + & {
    margin-top: 16px;
  }

  :first-child {
    line-height: 1.5;
    text-align: start;

    span {
      font-weight: 700;
    }

    .account {
      color: colors.$GRAY3;
    }
  }

  svg {
    width: 30px;
    height: 30px;
    margin: 10px;
    fill: colors.$PRIMARY;
  }
}
