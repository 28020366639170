// @import url('https://fonts.googleapis.com/css2?family=Nanum+Myeongjo:wght@400;700&display=swap');

// body,
// button,
// input,
// textarea {
//   font-family: 'Nanum Myeongjo', serif;
//   font-feature-settings: 'tnum';
// }

// input[type='number'] {
//   font-feature-settings: 'tnum';

//   &::-webkit-outer-spin-button,
//   &::-webkit-inner-spin-button {
//     margin: 0;
//     -webkit-appearance: none;
//   }
// }

body,
button,
input,
textarea {
  font-family: 'Malgun Gothic', sans-serif;
  font-feature-settings: 'tnum';
}

input[type='number'] {
  font-feature-settings: 'tnum';

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }
}
