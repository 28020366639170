@use '/src/styles/constants/colors';

.subtitle {
  margin-bottom: 16px;
  line-height: 1.5;
  color: colors.$GRAY1;
  text-align: center;
}

.form {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: colors.$SECONDARY;
  // z-index: -1;

  &.disabled::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    background-color: colors.$GRAYD;
    opacity: 0.7;
  }

  & > div {
    display: flex;
    width: 100%;
  }

  input,
  textarea {
    width: 100%;
    padding: 8px 10px;
    color: colors.$GRAY1;
    background-color: colors.$WHITE;
    border: 1px solid colors.$WHITE;
    border-radius: 4px;
    outline: none;
    transition: border 0.2s ease-in-out;

    &:focus {
      border: 1px solid colors.$PRIMARY;
    }
  }

  input {
    + input {
      margin-left: 10px;
    }
  }

  textarea {
    height: 60px;
    margin: 12px 0;
    line-height: 1.5;
    resize: none;
    border: none;
  }

  button {
    width: 80px;
    height: 40px;
    margin: 0 auto;
  }

  .errorMessage {
    margin-bottom: 12px;
    font-size: 12px;
    color: colors.$RED;
  }
}

.messageList {
  padding: 20px 20px 0px 20px;
  border-bottom: 1px solid colors.$GRAYE;

  .messageItem {
    padding: 14px 0;

    + .messageItem {
      border-top: 1px solid colors.$GRAYE;
    }
  }

  .messageHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 700;
    color: colors.$GRAY1;

    // 작성일
    // span:first-child {
    //   margin-left: 10px;
    //   font-size: 14px;
    //   color: colors.$GRAY9;
    // }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    svg {
      width: 16px;
      height: 16px;
      fill: colors.$GRAY9;
    }
  }

  p {
    margin-top: 8px;
    line-height: 1.5;
    color: colors.$GRAY3;
    white-space: pre-wrap;
  }
}

.messageTBox {
  display: flex;
  justify-content: end;
  span{
    margin-left: 10px;
    font-size: 14px;
    color: colors.$GRAY9;
  }
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;

  input {
    width: 100%;
    padding: 8px 10px;
    margin: 16px 0;
    color: colors.$GRAY1;
    border: 1px solid colors.$GRAYD;
    border-radius: 4px;
    transition: border 0.2s ease-in-out;

    &:focus {
      border: 1px solid colors.$PRIMARY;
    }
  }

  button {
    width: 80px;
    height: 40px;
  }

  .errorMessage {
    margin-bottom: 12px;
    font-size: 12px;
    color: colors.$RED;
  }
}

.description {
  color: colors.$GRAY1;
  text-align: left;
}
