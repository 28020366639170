@use '/src/styles/constants/colors';

.welcome {
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 34px;
    color: colors.$GRAY1;
    text-align: center;
  }

  .host {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
    margin-top: 20px;
    font-size: 14px;
    background-color: colors.$SECONDARY;
    // background-color: colors.$PRIMARY;

    & > div {
      display: flex;
      justify-content: space-between;
      width: 200px;

      + div {
        margin-top: 10px;
      }
    }

    span {
      font-size: 18px;
      font-weight: 700;
      color: colors.$GRAY1;
    }
  }
}
