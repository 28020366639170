// Chromatic
$RED: #ff3b30;
$ORANGE: #ff9500;
$YELLOW: #ffcc00;
$GREEN: #34c759;
$TEAL: #5ac8fa;
$BLUE: #007aff;
$INDIGO: #5856d6;
$PURPLE: #af52de;
$PINK: #ff2d55;

// Grayscale
$WHITE: #ffffff;
$BLACK: #000000;
$GRAY1: #1d1d1f;
$GRAY3: #3a474e;
$GRAY9: #94a2ad;
$GRAYD: #d1d8dc;
$GRAYE: #edeff1;

// $PRIMARY: #4fc0a6;
// $PRIMARY: #C89595;
$PRIMARY: #E48586;
$PRIMARY_PRESSED: #E9767C;
$SECONDARY: #FFF1F1;
