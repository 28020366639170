// Global styles
@import 'base/reset';
@import 'base/more';
@import 'base/fonts';

// Tailwind directives
// @import 'tailwindcss/base';
// @import 'tailwindcss/components';
// @import 'tailwindcss/utilities';

@tailwind base;
@tailwind components;
@tailwind utilities;